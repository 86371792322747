<template>
  <div class='processing-container'>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'ProposalsProcessing',
};
</script>

<style scoped>

</style>
