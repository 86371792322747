import { render, staticRenderFns } from "./ProposalsProcessing.vue?vue&type=template&id=b19753ea&scoped=true&"
import script from "./ProposalsProcessing.vue?vue&type=script&lang=js&"
export * from "./ProposalsProcessing.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b19753ea",
  null
  
)

export default component.exports